<template>
  <div>
    <the-header></the-header>
    <div>
      <div class="container">
        <slot name="toolBar"></slot>
      </div>
      <div class="container flex gap-3">
        <div class="content flex-1 w-full">
          <slot></slot>
        </div>
        <div class="right-side w-1/3 hidden lg:block">
          <div
            class="mb-3"
            v-for="(group, index) in topTrendingMovies"
            :class="{ 'mt-3': index == 0 }"
          >
            <outstanding-item-box
              :header="group.Header"
              :items="group.Items"
            ></outstanding-item-box>
          </div>
        </div>
      </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script setup lang="ts">
import TheFooter from '@/components/footer/TheFooter.vue';
import TheHeader from '@/components/header/TheHeader.vue';
import OutstandingItemBox from '@/components/outstanding-item-box/OutstandingItemBox.vue';
import { mapMovieItems } from '@/composables/mapping/use-mapping-movie';
import type { Group } from '@/entities/models/group';
import type { Movie } from '@/entities/models/moive';
import { UseStateKey } from '@/enums/use-state-enum';
import { getPagingResponse } from '~/utils/utils';

const { t } = useI18n();
const topTrendingMovies = useState<Group[]>(UseStateKey.TopTrendingMovies);
const { data } = await useFetch<Group[]>('/api/movies/top-trending', {
  onResponse({ response }) {
    let groupResponse = response._data;
    let groups = getPagingResponse<Group[]>(groupResponse);
    let res = groups.map((group) => ({
      ...group,
      URL: `/danh-sach${generateUrlFromText(group.Header)}`,
      Items: mapMovieItems<Movie>(group.Items),
    }));
    topTrendingMovies.value = res;
  },
});
</script>

<style scoped></style>
