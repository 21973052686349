<template>
  <footer>
    <div class="footer">
      <div class="container">
        <div class="flex gap-3 py-5 mt-2">
          <div class="web-info w-1/3">
            Xem phim online miễn phí chất lượng cao với phụ đề tiếng việt -
            thuyết minh - lồng tiếng. Tophimhay có nhiều thể loại phim phong
            phú, đặc sắc, nhiều bộ phim hay nhất - mới nhất.
          </div>
          <div class="flex-1 grid grid-cols-3 xs:grid-cols-1">
            <div v-for="group in footerItemGroup">
              <div
                class="group-name color-text-link font-bold text-lg capitalize mb-2"
              >
                {{ group.GroupName }}
              </div>
              <div v-for="item in group.Items" class="box-item-hover mb-2">
                <nuxt-link :to="item.URL">{{ item.Name }}</nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const footerItemGroup = reactive([
  {
    GroupName: 'Phim mới',
    Items: [
      {
        Name: 'Phim lẻ mới',
        URL: `/danh-sach${generateUrlFromText('Phim lẻ mới cập nhật')}`,
      },

      {
        Name: 'Phim bộ mới',
        URL: `/danh-sach${generateUrlFromText('Phim bộ mới cập nhật')}`,
      },
      // {
      //   Name: 'Phim chiếu rạp',
      //   URL: `/danh-sach${generateUrlFromText('Phim chiếu rạp')}`,
      // },
      // {
      //   Name: 'Phim thuyết minh',
      //   URL: `/danh-sach${generateUrlFromText('Phim thuyết minh')}`,
      // },
    ],
  },

  {
    GroupName: 'Phim lẻ',
    Items: [
      { Name: 'Phim hành động', URL: `/the-loai/hanh-dong` },

      {
        Name: 'Phim kinh dị',
        URL: `/the-loai/kinh-di`,
      },
      {
        Name: 'Phim viễn tưởng',
        URL: `/the-loai/vien-tuong`,
      },
      {
        Name: 'Phim gia đình',
        URL: `/the-loai/gia-dinh`,
      },
    ],
  },
  {
    GroupName: 'Phim bộ',
    Items: [
      { Name: 'Phim Việt Nam', URL: `/quoc-gia/viet-nam` },

      {
        Name: 'Phim Âu Mỹ',
        URL: `/quoc-gia/au-my`,
      },
      {
        Name: 'Phim Hàn Quốc',
        URL: `/quoc-gia/han-quoc`,
      },
      {
        Name: 'Phim Trung Quốc',
        URL: `/quoc-gia/trung-quoc`,
      },
    ],
  },
]);
</script>

<style scoped>
.footer {
  background: url(/icons/micro_carbon.png);
  min-height: 100px;
  padding: 20px 25px 15px;
  border-top: 3px solid #dacb46;
  border-bottom: 1px solid #111;
  margin-top: 20px;
}
</style>
