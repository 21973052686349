<template>
  <header>
    <div class="header-top">
      <div class="container flex items-center h-full">
        <div class="logo font-bold text-xl">
          <nuxt-link to="/" external>{{ $t('Logo') }}</nuxt-link>
        </div>
        <div class="search-bar ml-4">
          <u-input
            icon="i-heroicons-magnifying-glass-20-solid"
            size="md"
            class="w-full rounded"
            color="violet"
            :trailing="false"
            placeholder="Tìm kiếm phim..."
            v-model="searchValue"
            @keydown.enter="handleSearch"
          />
        </div>
      </div>
    </div>
    <div class="header-spread"></div>
    <div class="header-bottom">
      <nav>
        <div class="container">
          <ul class="flex">
            <li v-for="item in navbarItems">
              <div class="flex py-2 px-4 relative nav-item font-semibold">
                <nuxt-link :to="item?.To"
                  ><span class="item-label">{{ item?.Label }}</span></nuxt-link
                >
                <div
                  class="children absolute rounded-sm"
                  style="top: 99%; left: 0; z-index: 1000"
                >
                  <ul
                    class="sub grid grid-cols-1 gap-2 p-1 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6"
                    v-if="item?.Children"
                  >
                    <li v-for="child in item?.Children">
                      <div class="sub-item py-1 px-2">
                        <nuxt-link :to="child?.To">{{
                          child?.Label
                        }}</nuxt-link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script setup lang="ts">
import type { NavbarItem } from '@/entities/ui/header/navbar-item';
import { UseStateKey } from '@/enums/use-state-enum';

const router = useRouter();

const navbarItems = useState<NavbarItem[]>(UseStateKey.NavbarItems);

const searchValue = useState<NavbarItem[]>(UseStateKey.SearchValue);

async function handleSearch() {
  await router.push(`/tim-kiem?search=${searchValue.value}`);
  const { $bus } = useNuxtApp();
  $bus.emit('onSearch', searchValue.value);

  // const { $bus } = useNuxtApp();

  // $bus.$on('clickEvent', (data) => {
  //   // do whatever you want with data
  // });
}
</script>

<style scoped lang="scss">
.header {
  &-top {
    background-color: var(--app-header-top-background-color);
    height: 56px;
  }
  &-bottom {
    background-color: var(--app-header-bottom-background-color);
    color: var(--white-color);
    text-transform: capitalize;
    border-top: 2px solid var(--app-header-);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  &-spread {
    height: 2px;
    width: 100%;
    background-color: #d3c745;
    box-shadow: 10px 10px 8px 10px #888888;
  }
}
.nav-item {
  .children {
    display: none;
    width: 67vw;
  }
  &:hover,
  &:active {
    .children {
      display: flex;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
}
.sub {
  &-item {
    &:hover {
      background-color: var(--app-header-bottom-background-color);
    }
  }
}

.nav-item {
  &:hover {
    .item-label {
      color: #d3c745;
    }
    background-color: var(--app-header-top-background-color);
  }
}

.search-bar {
  width: 36vw;
}
</style>
