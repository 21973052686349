<template>
  <div class="outstanding-item-box">
    <div class="box-header mb-3">
      <h2 class="right-box-header star-icon text-2xl font-semibold">
        <span>{{ header }}</span>
      </h2>
    </div>
    <ul class="item-wrap p-1.5 rounded">
      <li
        class="box-item"
        v-for="(item, index) in items"
        :class="[index % 2 != 0 ? 'odd' : 'even']"
      >
        <nuxt-link :to="item?.URL">
          <div
            class="flex py-2 px-1 rounded relative"
            :class="[{ 'first-item': isFirstItem(index) }]"
            :style="
              isFirstItem(index)
                ? { backgroundImage: `url(${item?.Thumbnail})` }
                : {}
            "
          >
            <div class="item-image w-1/5 mr-2" v-if="index != 0">
              <nuxt-img :src="item?.Thumbnail" :height="60" />
            </div>
            <div
              class="item-info w-4/5"
              :class="[isFirstItem(index) ? 'w-full' : 'w-4/5']"
            >
              <div
                class="item-name font-semibold text-xs mb-1"
                :title="item?.Name"
              >
                {{ item?.Name }}
              </div>
              <div :class="{ 'flex justify-between': isFirstItem(index) }">
                <div
                  class="item-original-name text-xs"
                  :class="[{ 'color-text-blur': !isFirstItem(index) }]"
                >
                  {{ item?.OriginalName }}
                </div>
                <div
                  class="item-view text-xs"
                  :class="[{ 'color-text-blur': !isFirstItem(index) }]"
                >
                  <span>{{ item?.View }}</span> <span>{{ $t('View') }}</span>
                </div>
              </div>
            </div>
            <div class="quality-label font-bold text-xs">
              <span class="">{{ item?.Quality }}</span>
            </div>
          </div>
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { OutstandingItem } from '@/entities/ui/outstanding-item-box/outstanding-box';
import { defaultOutstandingItems } from './default-data';
const testItems = [
  {
    Name: 'EXHUMA: QUẬT MỘ TRÙNG MA',
    OriginalName: 'Exhuma',
    View: 123163,
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/exhuma-quat-mo-trung-ma/exhuma-quat-mo-trung-ma-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    View: 123163,
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    View: 123163,
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    View: 123163,
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    View: 123163,
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    View: 123163,
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    View: 123163,
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    View: 123163,
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    View: 123163,
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
  {
    Name: 'Kung Fu Panda 4',
    OriginalName: 'Kung Fu Panda 4',
    View: 123163,
    Rating: 5,
    Thumbnail:
      'https://phimmoitv.org/storage/images/kung-fu-panda-4/kung-fu-panda-4-thumb.jpg',
    Quality: 'HD',
    URL: '/phim/quat-mo-trung-ma',
  },
];

const props = withDefaults(
  defineProps<{
    items?: OutstandingItem[];
    header?: string;
  }>(),
  {
    //@ts-ignore
    items: defaultOutstandingItems as any,
  }
);
const isFirstItem = (index: number) => index == 0;
</script>

<style scoped lang="scss">
.item-wrap {
  background-color: var(--app-item-background-odd);
  .first-item {
    height: 120px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .item-name {
      font-size: 14px;
    }
    .item-info {
      padding: 12px 8px;
    }
    padding: 0 !important;
  }
}

.box-header {
  .star-icon {
    background: url('/assets/images/image-icon.png') no-repeat 0 -80px;
  }
  .right-box-header {
    color: #dacb46;
    text-shadow: 1px 1px 1px #1a1a1a;
    text-transform: uppercase;
    height: 32px;
    span {
      padding-left: 35px;
      padding-top: 3px;
      display: block;
    }
  }
}
.quality-label {
  right: 4px;
  top: 10px;
  position: absolute;
  background-color: #333;
  padding: 0 4px;
  border-radius: 2px;
  span {
  }
}
</style>
